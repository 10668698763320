module.exports = [{
      plugin: require('/builds/customers/soli-verbindet/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/builds/customers/soli-verbindet/node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#424241","showSpinner":false},
    },{
      plugin: require('/builds/customers/soli-verbindet/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1400},
    },{
      plugin: require('/builds/customers/soli-verbindet/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
