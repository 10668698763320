// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-single-template-tsx": () => import("/builds/customers/soli-verbindet/src/templates/SingleTemplate.tsx" /* webpackChunkName: "component---src-templates-single-template-tsx" */),
  "component---src-templates-single-supporter-template-tsx": () => import("/builds/customers/soli-verbindet/src/templates/SingleSupporterTemplate.tsx" /* webpackChunkName: "component---src-templates-single-supporter-template-tsx" */),
  "component---src-pages-404-tsx": () => import("/builds/customers/soli-verbindet/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aktuelles-tsx": () => import("/builds/customers/soli-verbindet/src/pages/aktuelles.tsx" /* webpackChunkName: "component---src-pages-aktuelles-tsx" */),
  "component---src-pages-beratung-tsx": () => import("/builds/customers/soli-verbindet/src/pages/beratung.tsx" /* webpackChunkName: "component---src-pages-beratung-tsx" */),
  "component---src-pages-dayanisma-tsx": () => import("/builds/customers/soli-verbindet/src/pages/dayanisma.tsx" /* webpackChunkName: "component---src-pages-dayanisma-tsx" */),
  "component---src-pages-index-tsx": () => import("/builds/customers/soli-verbindet/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("/builds/customers/soli-verbindet/src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-mitmachen-tsx": () => import("/builds/customers/soli-verbindet/src/pages/mitmachen.tsx" /* webpackChunkName: "component---src-pages-mitmachen-tsx" */),
  "component---src-pages-solidaritaet-tsx": () => import("/builds/customers/soli-verbindet/src/pages/solidaritaet.tsx" /* webpackChunkName: "component---src-pages-solidaritaet-tsx" */),
  "component---src-pages-solidarity-tsx": () => import("/builds/customers/soli-verbindet/src/pages/solidarity.tsx" /* webpackChunkName: "component---src-pages-solidarity-tsx" */),
  "component---src-pages-termine-tsx": () => import("/builds/customers/soli-verbindet/src/pages/termine.tsx" /* webpackChunkName: "component---src-pages-termine-tsx" */),
  "component---src-pages-unterstuetzer-innen-tsx": () => import("/builds/customers/soli-verbindet/src/pages/unterstuetzer_innen.tsx" /* webpackChunkName: "component---src-pages-unterstuetzer-innen-tsx" */)
}

